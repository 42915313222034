<template>
  <div class="page-does-not-exists">
    <img src="@/assets/img/404.svg" alt="404" />
    <h1>Страница не найдена</h1>
    <router-link to="/">На главную</router-link>
  </div>
</template>

<style lang="scss" scoped>
.page-does-not-exists {
  height: 100vh;
  display: flex;
  flex-flow: nowrap column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 64px;
  }

  h1 {
    margin-bottom: 24px;
    color: #000027;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.4px;
  }

  a {
    color: #000027;
    padding: 14px 24px 16px 24px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #e2e7ec;
    background: #fff;
  }
}
</style>
